
.count-particles {
    background: #000022;
    position: absolute;
    top: 48px;
    left: 0;
    width: 80px;
    color: #13E8E9;
    font-size: .8em;
    text-align: left;
    text-indent: 4px;
    line-height: 14px;
    padding-bottom: 2px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.js-count-particles {
    font-size: 1.1em;
}

#stats,
.count-particles {
    /* -webkit-user-select: none; */
    margin-top: 5px;
    margin-left: 5px;
}

#stats {
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}

.count-particles {
    border-radius: 0 0 3px 3px;
}


/* ---- particles.js container ---- */

#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.page-cover .container {
    margin-bottom: 60px;
    margin-top: 60px;
}

.page-cover h1, .page-cover h2 {
    font: bold 40px/1 Alegreya,serif;
    color: #fff;
    margin: 0 0 15px;
}

.amap:hover div.maq {
    background-color: blue;
    opacity: 0.4 !important;
}

.nightmode {
    background-color: #181818db !important;
    color: #fff;
}

    .nightmode .box {
        background-color: #262424;
    }

    .nightmode .social__link {
        color: #ffffff;
    }


    .nightmode .upworklogo {
        content: url(../img/upwork1.png);
    }

    .nightmode .box-inner::before, .nightmode .box-inner::after {
        background-color: #575a5b;
    }

    .nightmode .box-inner {
        background-color: #575a5b;
    }

    .nightmode .contacts-block__item a {
        color: #fff;
    }

        .nightmode .contacts-block__item a:hover {
            color: #ababad;
        }

    .nightmode .review-item {
        background-color: #262424;
    }

    .nightmode .preloader {
        background-color: #181818;
    }

    .nightmode .case-item__caption, .nightmode .review-item__caption, .nightmode .footer {
        color: #ccc;
    }
    .portfo {
        margin: auto;
    }

    .introvideo {
        min-height: 285px;
        border-radius: 25px;
    }