@font-face {
  font-family: 'icomoon';
  src:
    url('icomoon8f82.ttf?tbz8yr') format('truetype'),
    url('icomoon8f82.woff?tbz8yr') format('woff'),
    url('icomoon8f82.svg?tbz8yr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  display: inline-block;
  vertical-align: middle;
  text-transform: none;
  line-height: 1;
  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-down:before {
  content: "\e90e";
}
.icon-chevron-up:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e90b";
}
.icon-at:before {
  content: "\e90a";
}
.icon-reply:before {
  content: "\e909";
}
.icon-send:before {
  content: "\e907";
}
.icon-like-fill:before {
  content: "\e908";
}
.icon-like:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-skype:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-envelope:before {
  content: "\e902";
}
.icon-location:before {
  content: "\e903";
}
.icon-phone:before {
  content: "\e904";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-google:before {
  content: "\ea88";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-twitch:before {
  content: "\ea9f";
}
.icon-dribbble:before {
  content: "\eaa7";
}
.icon-behance:before {
  content: "\eaa8";
}
.icon-github:before {
  content: "\eab0";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-pinterest:before {
  content: "\ead1";
}
